import React from 'react';
import taqeAppIntegration from './taqeAppIntegration';

export type TForm = {
  "nome": string,
  "telefone": string,
  "data_nascimento": string,
  "cpf": string,
  "genero": string,
  "genero_trans": string,
  "raca": string,
  "qual_raca": string | null,
  "deficiencia": string[],
  "escolaridade": string,
  "estado": string,
  "cidade": string,
  "bairro": string,
  "nome_responsavel": string | null,
  "cpf_responsavel": string | null,
  "fez_coletivo": string,
  "escolha_coletivo": string | null,
  "ficou_sabendo": string,
  "data_termos": string,
  "cep": string,
  "rua": string,
  "email": string,
  "cnh": string[],
}

type TParams = {
  utm_source?: string,
  utm_medium?: string,
  utm_campaign?: string,
  utm_term?: string,
  utm_content?: string,
}

type IFormcontext = {
  form: TForm;
  setForm: React.Dispatch<React.SetStateAction<TForm>>;
  answered: boolean;
  setAnswered: React.Dispatch<React.SetStateAction<boolean>>;
  blockSend: boolean;
  setBlockSend: React.Dispatch<React.SetStateAction<boolean>>;
  utmParams: TParams;
  setUtmParams: React.Dispatch<React.SetStateAction<TParams>>;
  isRefused: boolean;
  setRefusal: React.Dispatch<React.SetStateAction<boolean>>;
};

const FormContext = React.createContext<IFormcontext | null>(null);

export const useForm = () => {
  const context = React.useContext(FormContext);
  if (!context) throw new Error('formContext deve estar dentro do Provider');
  return context;
};

export const FormStorage = ({ children }: { children: React.ReactNode }) => {
  const searchParams = new URLSearchParams(window.location.search);
    
  let userIntegrationData: any = taqeAppIntegration.loadUserIntegrationInfo({}, {
    fullName: searchParams.get('fullName'),
    email: searchParams.get('email'),
    cellphone: searchParams.get('cellphone'),
    birthdate: searchParams.get('birthdate'),
    cpf: searchParams.get('cpf'),
    gender: searchParams.get('gender'),
    disabilities: searchParams.get('disabilities'),
    levelEducation: searchParams.get('levelEducation'),
    city: searchParams.get('city'),
    state: searchParams.get('state'),
    zipCode: searchParams.get('zipCode'),
    district: searchParams.get('district'),
    street: searchParams.get('street'),
  });

  const [form, setForm] = React.useState<TForm>(
    Object.keys(userIntegrationData).length ? userIntegrationData : 
    localStorage.getItem('form') ? JSON.parse(localStorage.getItem('form') as string) :
    {
      nome: "",
      telefone: "",
      data_nascimento: "",
      cpf: "",
      genero: "",
      genero_trans: "",
      raca: "",
      qual_raca: null,
      deficiencia: "",
      escolaridade: "",
      cnh: "",
      estado: "",
      cidade: "",
      bairro: "",
      nome_responsavel: null,
      cpf_responsavel: null,
      fez_coletivo: "",
      escolha_coletivo: null,
      ficou_sabendo: "",
      data_termos: ""
    }
  )
  
  let localUtmParams: any = {
    utm_source: searchParams.get('utm_source') || null,
    utm_medium: searchParams.get('utm_medium') || null,
    utm_campaign: searchParams.get('utm_campaign') || null,
    utm_term: searchParams.get('utm_term') || null,
    utm_content: searchParams.get('utm_content') || null,
  }

  if (!localUtmParams || !Object.values(localUtmParams).some(value => value !== null && value !== undefined)) {
    localUtmParams = localStorage.getItem('params') && JSON.parse(localStorage.getItem('params') as string);
  }

  const [utmParams, setUtmParams] = React.useState(localUtmParams);

  const [answered, setAnswered] = React.useState<boolean>(false);

  const [blockSend, setBlockSend] = React.useState<boolean>(false);

  const [isRefused, setRefusal] = React.useState<boolean>(false);

  React.useEffect(() => {
    localStorage.setItem('form', JSON.stringify(form));
  }, [form]);

  React.useEffect(() => {
    localStorage.setItem('params', JSON.stringify(utmParams));
  }, [utmParams]);

  React.useEffect(() => {
    if (form.fez_coletivo === "Não, nunca concluí o Coletivo" || form.fez_coletivo === "Sim, concluí o Coletivo Jovem, presencialmente") {
      setForm({ ...form, escolha_coletivo: null });
    }
    // eslint-disable-next-line
  }, [form.fez_coletivo]);

  return (
    <FormContext.Provider value={{ form, setForm, answered, setAnswered, blockSend, setBlockSend, utmParams, setUtmParams, isRefused, setRefusal }}>
      {children}
    </FormContext.Provider>
  );
};
