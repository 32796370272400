import React from 'react';
import styles from './css/FormGroup.module.css';
import { useForm } from '../../Utils/FormContext';

type FormGroupProps = {
  title: string;
  name: string;
  placeholder: string;
  value: any;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: {
    value: string;
    key?: string;
  }[];
  disabled?: boolean;
  required: boolean;
};

const FormGroup: React.FC<FormGroupProps> = ({
  title,
  name,
  placeholder,
  value,
  options,
  disabled,
  required,
}) => {
  const { setForm } = useForm();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = value || [];
    const index = value.indexOf(e.target.value);

    if (index !== -1) {
      newValue.splice(index, 1);
    } else {
      newValue.push(e.target.value);
      if (e.target.value === 'none') {
        newValue = ['none'];
      } else {
        if (newValue.includes('none')) {
          newValue.splice(newValue.indexOf('none'), 1);
        }
      }
    }

    setForm((prev) => ({ ...prev, [name]: [...newValue] }));
  };

  return (
    <div className={styles.form_group}>
      <label
        htmlFor={name}
        className={`${styles.label} ${disabled ? styles.label_disabled : ''}`}
      >
        {title}
      </label>
      {options.map((option, index) => (
        <div>
          <input
            key={index}
            type="checkbox"
            name={name}
            value={option.key}
            onChange={handleChange}
            checked={value.includes(option.key)}
            disabled={disabled}
            id={`${name}-${option.value}`}
          />
          <label htmlFor={`${name}-${option.value}`}>{option.value}</label>
        </div>
      ))}
    </div>
  );
};

export default FormGroup;
