import './App.css';
import CocaInfos from './Components/CocaInfos';
import Form from './Components/Form';
import Rejection from './Components/Rejection';
import Success from './Components/Success';
import Support from './Components/Support';
import { useForm } from './Utils/FormContext';

const App = () => {
  const { answered, isRefused } = useForm();

  if (isRefused) {
    return <Rejection />;
  }

  return (
    <>
      {answered ? (
        <Success />
      ) : (
        <>
          <CocaInfos />
          <Form />
          <Support />
        </>
      )}
    </>
  );
};

export default App;
